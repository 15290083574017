window._ = require('lodash');
window.Swal =require('sweetalert2');
window.FilePondPluginImagePreview = require('filepond-plugin-image-preview');
window.FilePondPluginImageEdit = require('filepond-plugin-image-edit');
window.FilePondPluginImageTransform = require('filepond-plugin-image-transform');
window.FilePondPluginImageCrop = require('filepond-plugin-image-crop');
window.FilePondPluginImageResize = require('filepond-plugin-image-resize');
window.FilePondPluginImageExifOrientation = require('filepond-plugin-image-exif-orientation');
window.FilePondPluginFileValidateType = require('filepond-plugin-file-validate-type');
window.FilePondPluginFilePoster = require('filepond-plugin-file-poster');
window.FilePond = require('filepond');
window.TomSelect = require('tom-select');

const flatpickr = require("flatpickr");

try {
    require('@popperjs/core');
    require('bootstrap');
    require('../../vendor/bastinald/laravel-livewire-modals/resources/js/modals');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
